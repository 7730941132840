import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';

import Exercises from '../components/Exercises';
import SearchExercises from '../components/SearchExercises';
import HeroBanner from '../components/HeroBanner';

const Home = () => {
  const [exercises, setExercises] = useState([]);
  const [bodyPart, setBodyPart] = useState('all');

  return (
    <>
      <Box position='relative'>
        <HeroBanner />
      </Box>
      <Box backgroundColor='#f5f5f5' py='50px'>
        <Box sx={{ width: { xl: '1488px' } }} m='auto'>
          <SearchExercises
            setExercises={setExercises}
            bodyPart={bodyPart}
            setBodyPart={setBodyPart}
          />
          <Exercises
            setExercises={setExercises}
            exercises={exercises}
            bodyPart={bodyPart}
          />
        </Box>
      </Box>
      <Box
        sx={{
          paddingTop: { lg: '100px', xs: '40px' },
          paddingBottom: { lg: '100px', xs: '40px' },
        }}
        m='auto'
        className='gradient'
      >
        <Typography
          sx={{ width: { xl: '1488px' }, opacity: '0.8' }}
          variant='h1'
          m='auto'
          fontFamily='Oswald, sans-serif'
          fontWeight='900'
          textAlign='center'
          color='#fff'
          letterSpacing='25px'
          className='gradient-text'
        >
          Your Body can do it. It is time to convince your Mind
        </Typography>
      </Box>
    </>
  );
};

export default Home;

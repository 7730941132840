import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import bgVideo from '../assets/videos/bgVideo.mp4';

// import HeroBannerImage from '../assets/images/home-banner.webp';

const HeroBanner = () => (
  <Box
    sx={{ mt: { lg: '212px', xs: '70px' }, ml: { sm: '50px' } }}
    position='relative'
    p='20px'
  >
    <Typography color='#f00' fontWeight='600' fontSize='32px'>
      Fitness Club
    </Typography>
    <Typography
      fontWeight={700}
      sx={{ fontSize: { lg: '120px', xs: '42px' }, lineHeight: { lg: '120px', xs: '42px' }, color: '#1f1f1f', fontFamily: 'Poppins' }}
      mb='23px'
      mt='30px'
    >
      Sweat, Smile <br />
      And Repeat
    </Typography>
    <Typography fontSize='22px' lineHeight='35px'>
      Check out the most effective exercises personalized to you
    </Typography>
    <Stack>
      <a
        href='#exercises'
        style={{
          marginTop: '45px',
          textDecoration: 'none',
          width: '200px',
          textAlign: 'center',
          background: '#f00',
          padding: '14px',
          fontSize: '16px',
          textTransform: 'none',
          color: 'white',
          borderRadius: '4px',
        }}
      >
        Explore Exercises
      </a>
    </Stack>
    <Typography
      fontWeight={600}
      color='#f00'
      sx={{
        opacity: '0.1',
        display: { lg: 'block', xs: 'none' },
        fontSize: '200px',
      }}
    >
      Exercise
    </Typography>
    {/* <img src={HeroBannerImage} alt='hero-banner' className='hero-banner-img' /> */}

    <video className='bgVideo' autoPlay loop muted>
      <source src={bgVideo} type='video/mp4' />
    </video>
  </Box>
);

export default HeroBanner;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Box } from '@mui/material';

import './App.css';
import ExerciseDetail from './pages/ExerciseDetail';
import Home from './pages/Home';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

const App = () => (
  <>
    <Box sx={{ width: { xl: '100%' } }} m='auto'>
      <Navbar />
    </Box>
    <Box>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/exercise/:id' element={<ExerciseDetail />} />
      </Routes>
    </Box>
    <div style={{ backgroundColor: '#f00' }}>
      <Footer />
    </div>
  </>
);

export default App;

import React from 'react';
import { Box, Stack } from '@mui/material';
import Logo from '../assets/images/Logo-1.png';

const Footer = () => (
  <Box>
    <Stack gap='40px' sx={{ alignItems: 'center' }} flexWrap='wrap' px='80px' py='80px'>
      <img src={Logo} alt='logo' style={{ width: '150px', height: 'auto' }} />
    </Stack>
    {/* <Typography variant='h5' sx={{ fontSize: { lg: '28px', xs: '20px' } }} mt='41px' textAlign='center' pb='40px'>Made with ❤️ by JavaScript Mastery</Typography> */}
  </Box>
);

export default Footer;

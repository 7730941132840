import React from 'react';
import { Stack } from '@mui/material';
import { MutatingDots } from 'react-loader-spinner';

const Loader = () => (
  <Stack
    direction='row'
    justifyContent='center'
    alignItems='center'
    width='100%'
  >
    <MutatingDots
      height='100'
      width='100'
      color='orange'
      secondaryColor='orange'
      radius='12.5'
      ariaLabel='mutating-dots-loading'
      visible
    />
  </Stack>
);

export default Loader;

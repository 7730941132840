import React from 'react';
import { Link } from 'react-router-dom';
import { Stack } from '@mui/material';

import Logo from '../assets/images/logo.png';

const Navbar = () => (
  <Stack
    direction='row'
    sx={{
      gap: { sm: '140px', xs: '20px' },
      mt: { sm: '15px', xs: '10px' },
      justifyContent: 'none',
    }}
    lg={{
      justifyContent: 'space-between',
    }}
    style={{ justifyContent: 'space-between' }}
    px='20px'
    alignItems='center'
  >
    <Link to='/'>
      <img
        src={Logo}
        alt='logo'
        style={{ width: '135px', height: 'auto', margin: '0px 20px' }}
      />
    </Link>
    <Stack
      direction='row'
      sx={{
        gap: { lg: '40px', xs: '30px' },
        fontSize: { lg: '21px', xs: '16px' },
      }}
      alignItems='flex-end'
      fontWeight='500'
      textTransform='uppercase'
    >
      <Link
        to='/'
        style={{
          fontSize: '14px',
          letterSpacing: '2px',
          textDecoration: 'none',
          color: '#fff',
          borderBottom: '3px solid #f00',
        }}
      >
        Home
      </Link>
      <a
        href='#exercises'
        style={{
          fontSize: '14px',
          letterSpacing: '2px',
          textDecoration: 'none',
          color: '#fff',
          borderBottom: '3px solid transparent',
        }}
      >
        Exercises
      </a>
    </Stack>
  </Stack>
);

export default Navbar;
